<template>
  <v-app>
    <v-navigation-drawer 
      app
      v-model="drawer"
      width="335px"     
    >
      <v-card
        :elevation="0"
        style="border-radius: 0;"
        height="100vh"
        class="d-flex justify-space-between"
      >
      <!-- LISTA IZQUIERDA -->
        <div style="background-color: #3c3c3c; padding-top: 125px;">
          <div style="height: 260px; background-color: #3c3c3c;">
            <v-list 
              class="ma-0 pa-0"
              width="45px"
              v-for="section in sectionsLeft"
              :key="section.name"
              dense
              nav
            >
              <v-list-item 
                v-if="$store.state.permissions.includes(section.code)"
                :disabled="section.disabled"
                :class=" `mx-0 px-0 my-3 ${selectedMenu === section.name ? 'white' : ''}`"
                @click="changeSelectedMenu(section.name)"
                style="border-radius: 0;"
              >
                <v-list-item-icon class="mx-auto">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        v-bind="attrs"
                        v-on="on"
                        :class=" `${selectedMenu !== section.name ? 'white--text' : ''}`"
                      >
                        {{ section.icon }}
                      </v-icon>
                    </template>
                    <span>{{ section.title }}</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </div>
        </div>
        
        <!-- LISTA DERECHA -->
        <div>
          <v-list-item>
            <img src="@/assets/logo.png" alt="" width="180px" class="mx-auto mt-6 mb-12" @click="$router.push('/')" style="cursor: pointer;">
          </v-list-item>

        <v-list 
          width="290px"
          v-for="section in sectionsRight[selectedMenu]"
          :key="section.title"
          dense
          nav
        >
          <v-list-item>
            <v-list-item-subtitle class="ml-0">{{ section.title }}</v-list-item-subtitle>
          </v-list-item>
          <div
            v-for="item in section.items"
            :key="item.title"
          >
            <v-list-group
              v-if="item.subitems.length > 0 && $store.state.permissions.includes(item.code)"
              active-class="primary white--text"
            >
              <template v-slot:activator>
                <v-list-item-icon class="mr-2" >
                  <v-icon :disabled="item.disabled">{{ item.icon  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-icon v-if="item.disabled">
                  <v-icon disabled style="font-size: large; ">mdi-lock</v-icon>
                </v-list-item-icon>
              </template>
              <div
                v-for="subitem in item.subitems"
                :key="subitem.title"
              >
                <v-list-item
                  v-if="$store.state.permissions.includes(subitem.code)"
                  :to="subitem.link"
                  active-class="accent"
                  :disabled="subitem.disabled"
                >
                  <v-list-item-icon class="ml-4 mr-2" >
                    <v-icon :disabled="subitem.disabled">{{ subitem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                  <v-list-item-icon v-if="subitem.disabled">
                    <v-icon :disabled="subitem.disabled" style="font-size: large; ">mdi-lock</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </div>
            </v-list-group>

            <v-list-item 
              v-else-if="$store.state.permissions.includes(item.code)"
              :to="item.link"
              active-class="primary--text"
              :disabled="item.disabled"
            >
              <v-list-item-icon class="mr-2">
                <v-icon :disabled="item.disabled">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-icon v-if="item.disabled">
                <v-icon :disabled="item.disabled" style="font-size: large; ">mdi-lock</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </div>
          
        </v-list>
        </div>

      </v-card>
    </v-navigation-drawer>

    <v-app-bar 
      app
      color="grey lighten-4"
      :elevation="0"
    >
      <template >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </template>

      <v-spacer></v-spacer>

      <v-card
      class="d-flex justify-end align-center"
      color="transparent"
      flat
      >
        <!-- <v-btn icon>
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn> -->
        <v-card
          class="pl-12 pr-3"
          flat
          color="transparent"
        >
          {{$store.state.user?.name}}
        </v-card>
        <!-- <v-avatar
          size="36px"
          
        >
          <img v-if="true" src="@/assets/avatar.jpg" alt="Avatar">
          <v-icon
            v-else
          > mdi-account-circle-outline</v-icon>
        </v-avatar> -->
        <v-btn icon @click="logout()" :loading="logoutLoading">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-card>
    </v-app-bar>

    <v-main class="grey lighten-4">
      <v-container fluid class="pa-5">
        <slot/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

  export default {
    name: 'AppLayout',

    data: () => ({
      logoutLoading:false,
      //drawer: true,
      drawer: JSON.parse(localStorage.getItem("drawerState")) || false,
      selectedMenu: 'oferta',
      sectionsLeft: [
        // {
        //   // Dashboard
        //   name: 'dashboard',
        //   code: 'listar_ofertas',
        //   title:'Dashboard',
        //   icon: 'mdi-view-dashboard-outline',
        //   disabled: false,
        // },
        {
          // OFERTA
          name: 'oferta',
          code: 'listar_ofertas',
          title:'Oferta',
          icon: 'mdi-handshake-outline',
          disabled: false,
        },
        {
          // ACREDITACION
          name: 'acreditacion',          
          code: 'listar_acreditacion',          
          title:'Acreditación',
          icon: 'mdi-clipboard-account-outline',
          disabled: false,
        },
        {
          // MONITOREO Y CONTROL
          name: 'monitoreo',
          code: 'listar_monitoreo',
          title:'Monitoreo y Control',
          icon: 'mdi-speedometer',
          disabled: false,
        },
        {
          // EJECUCIÓN
          name: 'ejecucion',
          code: 'listar_ejecucion',
          title:'Ejecución',
          icon: 'mdi-account-hard-hat-outline',
          disabled: false,
        },
        {
          // CIERRE
          name: 'cierre',
          code: 'listar_cierre',
          title:'Cierre',
          icon: 'mdi-file-chart-check-outline',
          disabled: false,
        },
        {
          // MANTENEDOR
          name: 'mantenedor',
          code: 'listar_mantenedores',
          title:'Mantenedor',
          icon: 'mdi-cog',
          disabled: false,
        },
      ],
      sectionsRight: {
        dashboard: [
          {
            title:'Dashboard',
            code:'listar_dashboard',
            items: [
              { 
                title: 'Dashboard', 
                code: 'listar_dashboard',
                icon: 'mdi-view-dashboard', 
                link:'/',
                disabled:false,
                subitems: [] 
              },
            ]
          }
        ],
        oferta: [
          {
            title:'',
            code:'listar_ofertas',
            items: [
              { 
                title: 'Oferta', 
                code: 'listar_ofertas',
                icon: 'mdi-handshake',
                link:'/ofertas',
                disabled:false,
                subitems: [], 
              },   
              { 
                title: 'Administración', 
                code: 'listar_ofertas',
                icon: 'mdi-circle',
                disabled: true,
                link:'/ofertas-admin',
                subitems: [], 
              }, 
            ]
          }
        ],
        acreditacion: [
          {
            title:'Acreditación',
            code:'acreditacion',
            items: [
              { 
                title: 'Gestión HHEE', 
                code: 'listar_hhee',
                icon: 'mdi-account-clock',
                disabled:false,
                subitems: [
                  { 
                      title: 'Solicitudes', 
                      code: 'listar_index_hhee',
                      icon: 'mdi-file-document-edit',
                      link:'/horas-extra',
                      disabled:false,
                  },
                  { 
                      title: 'Autorización', 
                      code: 'listar_detail_hhee',
                      icon: 'mdi-file-document-check',
                      link:'/horas-extra-detalle',
                      disabled:false,
                  },
                  { 
                      title: 'Comparativa', 
                      code: 'reporte_hhee',
                      icon: 'mdi-file-compare',
                      link:'/horas-extra-informe',
                      disabled:false,
                  },
                  { 
                      title: 'Informe Semanal', 
                      code: 'reporte_hhee_semanal',
                      icon: 'mdi-calendar-week',
                      link:'/horas-extra-informe-semanal',
                      disabled:false,
                  },
                  { 
                      title: 'Reportes', 
                      code: 'reporte_hhee_area',
                      icon: 'mdi-file-document-multiple',
                      link:'/horas-extra-informe-area',
                      disabled:false,
                  },
                  { 
                      title: 'Solicitudes de Firma', 
                      code: 'listar_hhee_firma',
                      icon: 'mdi-file-sign',
                      link:'/horas-extra-firma',
                      disabled:false,
                  },
                ], 
              },  
              { 
                title: 'Gestión Personal', 
                code: 'listar_ofertas',
                icon: 'mdi-account-multiple-check',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
              { 
                title: 'Gestión EPP', 
                code: 'listar_ofertas',
                icon: 'mdi-hard-hat',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
              { 
                title: 'Gestión Terreno', 
                code: 'listar_ofertas',
                icon: 'mdi-account-hard-hat',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
            ]
          }
        ],
        monitoreo: [
          {
            title:'Monitoreo y Control',
            code:'acreditacion',
            items: [
              { 
                title: 'Planificación', 
                code: 'listar_proyectos',
                icon: 'mdi-calendar-month-outline',
                disabled:false,
                subitems: [
                  { 
                      title: 'Proyectos', 
                      code: 'listar_planificacion',
                      icon: 'mdi-hammer',
                      link:'/proyectos',
                      disabled:false,
                  },
                ], 
              },  
              { 
                title: 'Autorización OT', 
                code: 'listar_hhee',
                icon: 'mdi-truck-check',
                disabled:true,
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ], 
              },  
              { 
                title: 'Cronograma Actividades Previas', 
                code: 'listar_ofertas',
                icon: 'mdi-circle',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
              { 
                title: 'Control Avance Terreno', 
                code: 'listar_ofertas',
                icon: 'mdi-circle',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
              { 
                title: 'Control de Costos', 
                code: 'listar_ofertas',
                icon: 'mdi-circle',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
              { 
                title: 'Quiebres', 
                code: 'listar_ofertas',
                icon: 'mdi-circle',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
              { 
                title: 'EPP', 
                code: 'listar_ofertas',
                icon: 'mdi-circle',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
            ]
          }
        ],
        ejecucion: [
          {
            title:'Ejecución',
            code:'acreditacion',
            items: [
              { 
                title: 'Gestión Pañol', 
                code: 'listar_hhee',
                icon: 'mdi-store',
                disabled:true,
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ], 
              },  
              { 
                title: 'Gestión Calidad', 
                code: 'listar_ofertas',
                icon: 'mdi-circle',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
              { 
                title: 'Comisionamiento', 
                code: 'listar_ofertas',
                icon: 'mdi-circle',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
              { 
                title: 'Trabajos emergentes', 
                code: 'listar_ofertas',
                icon: 'mdi-circle',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
              { 
                title: 'EPP', 
                code: 'listar_ofertas',
                icon: 'mdi-circle',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
            ]
          }
        ],
        cierre: [
          {
            title:'Cierre',
            code:'acreditacion',
            items: [
              { 
                title: 'Generación Informes Técnicos', 
                code: 'listar_hhee',
                icon: 'mdi-circle',
                disabled:true,
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ], 
              },  
              { 
                title: 'Cierre Comercial', 
                code: 'listar_ofertas',
                icon: 'mdi-circle',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },  
              { 
                title: 'Encuesta Satisfacción', 
                code: 'listar_ofertas',
                icon: 'mdi-file-chart-check',
                subitems: [
                  // { 
                  //     title: 'Solicitudes', 
                  //     code: 'listar_index_hhee',
                  //     icon: 'mdi-circle',
                  //     link:'/horas-extra',
                  //     disabled:false,
                  // },
                ],
                disabled:true,
              },   
            ]
          }
        ],
        mantenedor: [
          {
            title:'Mantenedores',
            code: 'mantenedores',
            items: [
              {

                title: 'Control de acceso', 
                code: 'listar_control_acceso',
                icon: 'mdi-view-dashboard', 
                disabled:false,
                subitems: [
                  { title: 'Roles', 
                    code: 'listar_roles',
                    icon: 'mdi-circle', 
                    link:"/roles", 
                    disabled:false 
                  },
                  // { title: 'Permisos', 
                  //   code: 'listar_dashboard',
                  //   icon: 'mdi-circle', 
                  //   link:"/permisos", 
                  //   disabled:false 
                  // },
                  { title: 'Usuarios', 
                    code: 'listar_usuarios',
                    icon: 'mdi-account-group', 
                    link:"/usuarios", 
                    disabled:false 
                  },
                ] 
              },
              { 
                title: 'Clientes', 
                code: 'listar_clientes',
                icon: 'mdi-view-dashboard', 
                disabled:false,
                subitems: [
                  // { title: 'Actividades', 
                  //   code: 'listar_dashboard',
                  //   icon: 'mdi-lock', 
                  //   link: '/about', 
                  //   disabled:true 
                  // },
                  { title: 'Empresas', 
                    code: 'listar_empresas',
                    icon: 'mdi-circle', 
                    link: '/empresas', 
                    disabled:false 
                  },
                ] 
              },
              { 
                title: 'Configuracion general', 
                code: 'listar_configuracion_general',
                icon: 'mdi-view-dashboard', 
                disabled:false,
                subitems: [
                  { title: 'Zonas', 
                    icon: 'mdi-circle',
                    code: 'listar_zonas',
                    link: '/zonas', 
                    disabled:false 
                  },
                  { title: '$ Aprobación SPOT', 
                    icon: 'mdi-circle',
                    code: 'listar_cargos',
                    link: '/rangos', 
                    disabled:false 
                  },
                  { title: 'Mano de Obra', 
                    code: 'listar_cargos',
                    icon: 'mdi-circle', 
                    link: '/mano-obra', 
                    disabled:false 
                  },
                  { title: 'Gastos Generales', 
                    code: 'listar_gastos_generales',
                    icon: 'mdi-circle', 
                    link: '/gastos-generales', 
                    disabled:false 
                  },
                  { title: 'Equipos y Servicios', 
                    code: 'listar_equipos',
                    icon: 'mdi-circle', 
                    link: '/equipos-servicios', 
                    disabled:false 
                  },
                  { title: 'Listas Seleccionables', 
                    code: 'listar_listas_seleccionables',
                    icon: 'mdi-circle', 
                    link: '/listas-seleccionables', 
                    disabled:false 
                  },
                  { title: 'Importar Horas Extras', 
                    code: 'listar_hhee_buk',
                    icon: 'mdi-circle', 
                    link: '/horas-extra-buk', 
                    disabled:false 
                  },
                ] 
              },
              { 
                title: 'Herramientas', 
                code: 'listar_herramientas',
                icon: 'mdi-tools', 
                disabled:false,
                subitems: [
                  { title: 'Tarjetas de Bloqueo', 
                    icon: 'mdi-close-octagon',
                    code: 'listar_tarjetas_bloqueo',
                    link: '/tarjetas-bloqueo', 
                    disabled:false 
                  },
                ]
              },
            ]
          }
        ],
      },
      sections: [
        {
          title: "INICIO",
          code: 'menu_inicio',
          items: [
            { 
              title: 'Dashboard', 
              code: 'listar_dashboard',
              icon: 'mdi-view-dashboard', 
              link:'/',
              disabled:false,
              subitems: [] 
            },
          ]
        },
        {
          title: "SISTEMA",
          code: 'menu_sistema',
          items: [
            { 
              title: 'Ventas', 
              code: 'listar_ventas',
              icon: 'mdi-view-dashboard', 
              disabled:false,
              subitems: [
                  { 
                      title: 'Oferta', 
                      code: 'listar_ofertas',
                      icon: 'mdi-circle',
                      link:'/ofertas',
                      disabled:false,
                  },
                  { 
                      title: 'Acreditación', 
                      code: 'listar_dacreditacion',
                      icon: 'mdi-lock',
                      link:'/',
                      disabled:true,
                  },
              ] 
            },
            { 
              title: 'Horas Extras', 
              code: 'listar_hhee',
              icon: 'mdi-view-dashboard', 
              disabled:false,
              subitems: [
                  { 
                      title: 'Solicitudes', 
                      code: 'listar_index_hhee',
                      icon: 'mdi-circle',
                      link:'/horas-extra',
                      disabled:false,
                  },
                  { 
                      title: 'Autorización', 
                      code: 'listar_detail_hhee',
                      icon: 'mdi-circle',
                      link:'/horas-extra-detalle',
                      disabled:false,
                  },
                  { 
                      title: 'Comparativa', 
                      code: 'reporte_hhee',
                      icon: 'mdi-circle',
                      link:'/horas-extra-informe',
                      disabled:false,
                  },
                  { 
                      title: 'Informe Semanal', 
                      code: 'reporte_hhee_semanal',
                      icon: 'mdi-circle',
                      link:'/horas-extra-informe-semanal',
                      disabled:false,
                  },
                  { 
                      title: 'Solicitudes de Firma', 
                      code: 'listar_hhee_firma',
                      icon: 'mdi-image',
                      link:'/horas-extra-firma',
                      disabled:false,
                  },
              ] 
            },
          ]
        },
        {
          title: "MANTENEDORES",
          code: 'menu_mantenedores',
          items: [
            { 
              title: 'Control de acceso', 
              code: 'listar_control_acceso',
              icon: 'mdi-view-dashboard', 
              disabled:false,
              subitems: [
                { title: 'Roles', 
                  code: 'listar_roles',
                  icon: 'mdi-circle', 
                  link:"/roles", 
                  disabled:false 
                },
                // { title: 'Permisos', 
                //   code: 'listar_dashboard',
                //   icon: 'mdi-circle', 
                //   link:"/permisos", 
                //   disabled:false 
                // },
                { title: 'Usuarios', 
                  code: 'listar_usuarios',
                  icon: 'mdi-account-group', 
                  link:"/usuarios", 
                  disabled:false 
                },
              ] 
            },
            { 
              title: 'Clientes', 
              code: 'listar_clientes',
              icon: 'mdi-view-dashboard', 
              disabled:false,
              subitems: [
                // { title: 'Actividades', 
                //   code: 'listar_dashboard',
                //   icon: 'mdi-lock', 
                //   link: '/about', 
                //   disabled:true 
                // },
                { title: 'Empresas', 
                  code: 'listar_empresas',
                  icon: 'mdi-circle', 
                  link: '/empresas', 
                  disabled:false 
                },
              ] 
            },
            { 
              title: 'Configuracion general', 
              code: 'listar_configuracion_general',
              icon: 'mdi-view-dashboard', 
              disabled:false,
              subitems: [
                { title: 'Zonas', 
                  icon: 'mdi-circle',
                  code: 'listar_zonas',
                  link: '/zonas', 
                  disabled:false 
                 },
                { title: '$ Aprobación SPOT', 
                  icon: 'mdi-circle',
                  code: 'listar_cargos',
                  link: '/rangos', 
                  disabled:false 
                 },
                { title: 'Mano de Obra', 
                  code: 'listar_cargos',
                  icon: 'mdi-circle', 
                  link: '/mano-obra', 
                  disabled:false 
                },
                { title: 'Gastos Generales', 
                  code: 'listar_gastos_generales',
                  icon: 'mdi-circle', 
                  link: '/gastos-generales', 
                  disabled:false 
                },
                { title: 'Equipos y Servicios', 
                  code: 'listar_equipos',
                  icon: 'mdi-circle', 
                  link: '/equipos-servicios', 
                  disabled:false 
                },
                { title: 'Listas Seleccionables', 
                  code: 'listar_listas_seleccionables',
                  icon: 'mdi-circle', 
                  link: '/listas-seleccionables', 
                  disabled:false 
                },
                { title: 'Importar Horas Extras', 
                  code: 'listar_hhee_buk',
                  icon: 'mdi-circle', 
                  link: '/horas-extra-buk', 
                  disabled:false 
                },
              ] 
            },
          ]
        }
      ],
      right: null,
    }),
    watch: {
      drawer(newVal) {
        localStorage.setItem("drawerState", JSON.stringify(newVal));
      }
    },
    methods: {
      changeSelectedMenu(select){
        this.selectedMenu = select
      },
      async logout() {
        try {
          this.logoutLoading = true;
          // Asegúrate de estar llamando a this.$store correctamente
          await this.$store.dispatch('logout');
          
          // Elimina el token del localStorage
          localStorage.removeItem('auth_token');
          
          this.logoutLoading = false;

          // Redirige al usuario al login
          this.$router.push('/login');
        } catch (error) {
          console.error('Error al cerrar sesión:', error);
        }
      },
      setFirstAvailableMenu() {
        for (let section of this.sectionsLeft) {
          if (this.$store.state.permissions.includes(section.code)) {
            this.selectedMenu = section.name;
            break;
          }
        }
      },
    },

    created() {
      this.setFirstAvailableMenu();
    }
  };
</script>
